<template>
    <div class="OverTimeView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable   
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="加班申请单号"
                    placeholder="加班申请单号"
                />
                <select-component
                    name="state"
                    lable="审核状态"
                    :farr="this.$store.state.state"
                    @clk="confirm_state"
                ></select-component>
                <date-component
                    title="开始日期"
                    @clk="confirm_start_time"
                ></date-component>
                <date-component
                    title="结束日期"
                    @clk="confirm_end_time"
                ></date-component>
                <select-component
                    name="create_staff"
                    lable="申请人"
                    :farr="all_staff"
                    @clk="confirm_create_staff"
                ></select-component>
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <div class="insert">
            <van-button v-if="this.$_has('新增加班申请')" icon="add-o" type="primary" round plain size="small" @click="show_add">创建加班单</van-button>
        </div>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.title}}</span>
                    </template>
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="v.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="v.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="v.state===3">已驳回</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="单号" :value="v.name" :border="false" />
                <van-cell class="note_cell" title="加班时长" :value="v.duration+'小时'" :border="false" />
                <van-cell class="note_cell" title="开始时间" :value="v.start_time" :border="false" />
                <van-cell class="note_cell" title="结束时间" :value="v.end_time" :border="false" />
                <van-cell class="note_cell" title="当前审批人" :value="v.current_examine_staff_name" :border="false" />
                <van-cell class="note_cell" title="申请人" :value="v.create_staff_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import DateComponent from '@/components/common/DateComponent.vue'

import { over_time_index_request } from '@/network/hrm/OverTime.js'
import { staff_list_request } from '@/network/list.js'

export default {
    name:'OverTimeView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '加班申请管理',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            show_popup: false,
            form_key: Date.now(),
            cond: {
                name: '',
                state: '',
                start_time: '',
                end_time: '',
                create_staff: '',
                page: 1
            },
            all_staff: []
        }
    },
    computed:{},
    methods:{
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_over_time() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            over_time_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_state(e) {
            this.cond.state = e.id
        },
        confirm_create_staff(e) {
            this.cond.create_staff = e.id
        },
        confirm_start_time(e) {
            this.cond.start_time = e
        },
        confirm_end_time(e) {
            this.cond.end_time = e
        },
        search() {
            this.cond.page = 1
            this.get_over_time()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                name: '',
                state: '',
                start_time: '',
                end_time: '',
                create_staff: '',
                page: 1
            }
            this.form_key = Date.now()
        },
        show_details(v) {
            if(this.$_has('加班申请详情')) {
                this.$router.push({
                    name: 'OverTimeDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        show_add() {
            this.$router.push({
                name: 'OverTimeDetails',
                query: {
                    id: 0
                }
            })
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                over_time_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_over_time()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        DateComponent
    },
    watch:{}
}
</script>

<style>
.OverTimeView .van-cell-group {
    margin-bottom: 0.2rem;
}
.OverTimeView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.OverTimeView .note_cell {
    padding: 0.1rem 0.42667rem
}
.OverTimeView .search_title {
    text-align: center;
}
.OverTimeView .search_button {
    margin: 0.5rem;
}
.OverTimeView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>